<div class="mat-bg absolute flex h-full w-full flex-col items-center justify-center text-white">
  <div class="flex flex-col items-center">
    <mat-icon svgIcon="logo" class="mb-12 !h-10 !w-[200px]"></mat-icon>
    <div class="flex flex-col space-y-2">
      <button mat-raised-button class="!h-16 !w-[180px]" [disabled]="loading()" (click)="login()">
        <div class="flex items-center justify-center space-x-4">
          <mat-icon svgIcon="ms"></mat-icon>
          <span class="text-lg font-bold">Login</span>
        </div>
      </button>
      <a mat-button class="!w-[180px]" [href]="appInBrowserHref" target="_blank" [disabled]="loading()">
        <div class="flex items-center justify-center space-x-4">
          <span>Open in browser</span>
          <mat-icon>open_in_new</mat-icon>
        </div>
      </a>
    </div>
  </div>
</div>
<ng-container *ngIf="requireTeamsFrame()">
  <iframe
    id="iframe_teams"
    [ngClass]="['border-none', (showRegister() && 'absolute inset-0 z-10 h-full w-full') || '-z-10 h-0 w-0 opacity-0']"
    style="border: none; width: 0; height: 0; opacity: 0; visibility: hidden"
    src="about:blank"
  ></iframe>
</ng-container>
