import { MsalService } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  BrowserAuthError,
  InteractionRequiredAuthError,
  ServerError,
} from '@azure/msal-browser';
import { environment } from '@env/environment';
import { isTeamsWindow } from '@env/msal';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Logger, Scope, initialScopes as defaultInitialScopes } from 'timeghost-api';
import { InitServiceArgs } from './app-load-handler';

import * as Sentry from '@sentry/angular-ivy';
import { openURL } from './_helpers/aLink';
import { toPromise } from './_helpers/promise';
import { queryParams, stringify } from './_helpers/utils';
import { EMAIL_REGEX } from './_validators/custom-validators';
import { teamsScopes } from './pages/teams/teams-login-page/teams-login-page.component';
const emptyPromise = () => new Promise(() => {});
const log = new Logger('AppAuth-Handler');
let initialScopes: string[] = defaultInitialScopes;
const requireLoginErrors = [
  'interaction_required',
  'login_required',
  'invalid_grant',
  'no_account_error',
  'consent_required',
  'no_tokens_found',
  'invalid_request',
  'monitor_window_timeout',
  'redirect_in_iframe',
] as const;
type RequireLoginError = (typeof requireLoginErrors)[number];
export function hasRequireLoginError(code: StringLiteral<RequireLoginError>) {
  return code && requireLoginErrors.find((c) => c === code.toLowerCase());
}

function checkAndSetActiveAccount(msal: MsalService) {
  /**
   * If no active account set but there are accounts signed in, sets first account to active account
   * To use active account set here, subscribe to inProgress$ first in your component
   * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
   */
  let activeAccount = msal.instance.getActiveAccount();

  if (!activeAccount && msal.instance.getAllAccounts().length > 0) {
    let accounts = msal.instance.getAllAccounts();
    msal.instance.setActiveAccount((activeAccount = accounts[0]));
  }
  return activeAccount;
}
const initFinish = new Subject<void>();
const closeInit = () => (initFinish.next(), initFinish.complete());
function login(msal: MsalService, isTeams?: boolean, consentRequired?: boolean) {
  const persistQP = queryParams();
  // expi - date of qp saved
  localStorage.qpcache = stringify({ ...persistQP, _expi: new Date().toISOString() });
  // if (isTeams) {
  //   msal.instance.setActiveAccount(null); // prepare to bypass teams guard
  //   location.href = `${window.location.origin}/auth/teams/login?in_teams=1`; // redirect to custom teams login site
  //   return emptyPromise();
  // }
  if (isTeams) checkTeamsAndRedirect(msal, true);
  return msal
    .loginRedirect({
      redirectStartPage: window.location.href,
      scopes: initialScopes,
      ...(persistQP.prompt === 'consent' || consentRequired ? { prompt: 'consent' } : {}),
    })
    ?.toPromise();
}
function ssoLogin(this: MsalService, ssoToken: string) {
  const persistQP = queryParams();
  // expi - date of qp saved
  localStorage.qpcache = stringify({ ...persistQP, _expi: new Date().toISOString() });
  return this.ssoSilent({ scopes: initialScopes })?.toPromise();
}
function shouldHandleMsalError(err: BrowserAuthError) {
  if (err.errorCode === 'interaction_in_progress') return false;

  return true;
}
function ssoErrorHandler(err: InteractionRequiredAuthError | BrowserAuthError) {
  if (hasRequireLoginError(err.errorCode)) return true;
  return false;
}
function setMsalSentryTags(account?: AccountInfo) {
  if (account && EMAIL_REGEX.test(account.username)) {
    Sentry.setTags({
      'user.id': account.localAccountId,
      'user.email': account.username,
    });
    if (account.tenantId) {
      Sentry.setTags({
        'user.tenantId': account.tenantId,
      });
    }
  }
}
async function checkTeamsAndRedirect(msal: MsalService, ignoreAccount?: boolean) {
  if (isTeamsWindow() && (ignoreAccount === true || !checkAndSetActiveAccount(msal))) {
    log.info('redirect to teams auth button page');
    localStorage.teams_auth_scopes = initialScopes.toString();
    localStorage.teams_auth_clientId = environment.adalConfig.clientId;
    openURL(window.location.origin + '/teams-auth-iframe.html').click();
    return await emptyPromise();
  }
  return null;
}
const allowedPrompts = ['select_account', 'consent'];
export const authorizeMsal = async ({
  msal,
  teams,
  http,
}: {
  msal: InitServiceArgs['msal'];
  teams: InitServiceArgs['teamsService'];
  http: InitServiceArgs['http'];
}) => {
  const params = Object.fromEntries(new URL(location.href).searchParams) || {};
  log.debug('init Auth', 'teams:', teams.isInitialized());
  if (!msal.instance.getActiveAccount() || msal.instance.getAllAccounts().length > 0) checkAndSetActiveAccount(msal);
  let account = checkAndSetActiveAccount(msal);
  if (!environment.production) window.msal = msal.instance;
  setMsalSentryTags(account);
  const isTeams = teams.isInitialized();
  if (isTeams) initialScopes = teamsScopes; // allow store offline token in local storage (encrypted)
  return await new Promise(async (resolve, reject) => {
    msal
      .handleRedirectObservable()
      .pipe(
        takeUntil(initFinish),
        switchMap(async (auth: AuthenticationResult) => {
          function getSSO() {
            return toPromise(
              msal.ssoSilent({
                scopes: [Scope.Me],
                ...(params.type && allowedPrompts.includes(params.type) ? { prompt: params.type } : {}),
              }),
            )
              .then((ssoResult) => {
                log.debug('sso result:', ssoResult);
                return ssoResult;
              })
              .catch(async (err): Promise<AuthenticationResult> => {
                log.error('sso err:', err);
                if (ssoErrorHandler(err) && !teams?.isInitialized()) {
                  return login(msal, isTeams, params?.type === 'consent') as any;
                }
                return null;
              });
          }
          if (!auth) {
            const sso = await getSSO();
            if (sso?.account) {
              account = sso.account;
              msal.instance.setActiveAccount(account);
            } else account = checkAndSetActiveAccount(msal);
            if (account) {
              return await msal
                .acquireTokenSilent({
                  account,
                  scopes: [Scope.Me],
                })
                .toPromise()
                .then((auth) => {
                  setMsalSentryTags(account);
                  return auth;
                })
                .then(resolve)
                .catch(reject);
            } else {
              return await login(msal, isTeams).then(resolve).catch(reject);
            }
          } else {
            msal.instance.setActiveAccount(auth.account);
            return await msal
              .acquireTokenSilent({
                scopes: [Scope.Me],
              })
              .toPromise()
              .then((auth) => {
                setMsalSentryTags(account);
                return auth;
              })
              .then(resolve)
              .catch(reject);
          }
        }),
      )
      .subscribe({
        error: (err) => {
          log.error(err);
          reject(err);
          closeInit();
        },
      });
  })
    .then((data) => {
      closeInit();
      teams.getInstance()?.app.notifySuccess();
      return data;
    })
    .catch(async (err: any) => {
      if (
        err instanceof InteractionRequiredAuthError ||
        err instanceof BrowserAuthError ||
        err instanceof ServerError
      ) {
        // move user to login if expired auth or interaction required
        if (err.errorCode && hasRequireLoginError(err.errorCode)) {
          if (teams.isInitialized()) {
            teams.getInstance().app.notifyAppLoaded();
          }
          await checkTeamsAndRedirect(msal, true);
          return await login(msal, isTeams, err.errorCode === 'consent_required');
        }
      }
      return await Promise.reject(err);
    });
};
const initialize = async ({ msal, teamsService, http }: InitServiceArgs) => {
  return await authorizeMsal({ msal, teams: teamsService, http });
};
export default initialize;
